<template>
  <b-overlay :show="fetching" rounded="sm">
    <div class="page">
      <div class="page__title">
        {{ getModuleConfig("staff_list_page.intro.form_title") }}
      </div>
      <div class="page__desc text-center">
        {{ getModuleConfig("staff_list_page.intro.form_desc") }}
      </div>
      <div class="field" v-if="isCanBinding">
        <div
          class="page__content"
          v-if="getModuleConfig('staff_list_page.intro.content')"
          v-html="getModuleConfig('staff_list_page.intro.content')"
        ></div>
      </div>
      <div v-else>
        <div
          class="page__content"
          v-if="getModuleConfig('staff_list_page.multiple_bind.forbid_text')"
          v-html="getModuleConfig('staff_list_page.multiple_bind.forbid_text')"
        ></div>
      </div>
    </div>

    <div class="page__button s-space-y-4">
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_list_page.intro.button_text') && isCanBinding"
        :button-text="getModuleConfig('staff_list_page.intro.button_text')"
        :button-url="getModuleConfig('staff_list_page.intro.button_url')"
        :type="getModuleConfig('staff_list_page.intro.button_action')"
      >
      </MemberButton>
      <MemberButton
        :button-style="`s-btn-outline-primary`"
        v-if="getModuleConfig('staff_list_page.multiple_bind.button_text') && !isCanBinding"
        :button-text="getModuleConfig('staff_list_page.multiple_bind.button_text')"
        :button-url="getModuleConfig('staff_list_page.multiple_bind.button_url')"
        :type="getModuleConfig('staff_list_page.multiple_bind.button_action')"
      >
      </MemberButton>
    </div>
  </b-overlay>
</template>

<script>
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import { mapGetters } from "vuex";
import staffApi from "@/apis/liff/v2/staff";
import mixinStaff from "@/mixins/liff/staff";

export default {
  mixins: [mixinStaff],
  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
    ...mapGetters({
      getModule: "liffModule/getModule",
    })
  },
  components: {
    MemberButton,
  },
  data() {
    return {
      fetching: true,
      lineData: {},
      fetchingStaff: false,
      isCanBinding: true
    };
  },
  watch: {},

  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      await this.checkBinding();
      if (!this.isCanBinding) {
        this.fetching = false;
        return
      }
      if (this.getModuleConfig("staff_list_page.intro.redirect_to_oa")) {
        // 如果可以綁定 但是有開啟轉導到OA
        window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/redirect-to-oa`;
      }
      this.handleSubmit()
      this.fetching = false;
    },
    async checkBinding() {
      await staffApi.checkBinding().then((res) => {
        // 如果綁定 且可以多重綁定
        if (res.isBound) {
          if (this.getModuleConfig("staff_list_page.intro.multiple_bind.enable")) {
            this.isCanBinding = true
          } else {
            this.isCanBinding = false
          }
        } else {
          this.isCanBinding = true
        }
      });
    },
    async handleSubmit() {
      try {
        this.fetching = true;
        await this.doSubmit();
      } catch (error) {
        this.fetching = false;
      }
    },
    doSubmit() {
      return new Promise(() => {
        staffApi
          .sendListBindTemplate()
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";
.sales {
  margin: 5px -16px;
  padding: 16px 0;

  &__wrapper {
    //min-height: 460px;
  }

  &__button {
    opacity: 0.5;
    &__active {
      opacity: 1;
    }
  }

  &__item {
    width: 220px;
    height: revert;

    .card {
      border-radius: 0.5rem;
      height: 100%;

      .card-img-top {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    }
  }

  ::v-deep.swiper-wrapper {
    align-items: stretch;
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: gray;
    background: white;
    border-radius: 100%;
    aspect-ratio: 1;
    width: auto;
    &::after {
      font-size: 1.5rem;
    }
  }
}

.page {
  padding: 5px 5px;
}

::v-deep .spinner-border {
  border-width: 0.2em !important;
}
</style>
